export const UiTypes = Object.freeze({
  multipleSection: 'multipleSection',
  section: 'section',
  tableSection: 'tableSection',
  tabSection: 'tabSection',
  group: 'group',
  endorsements: 'endorsements',
  spreadsheet: 'spreadsheet',
  moneyTable: 'moneyTable',
  ratingRules: 'ratingRules',
  checkboxes: 'checkboxes',
  boolean: 'boolean',
  money: 'money',
  moneySelect: 'moneySelect',
  moneyCurrency: 'moneyCurrency',
  hidden: 'hidden',
  multipleSectionIndex: 'multipleSectionIndex',
  text: 'text',
  matrix: 'matrix',
  aiButton: 'aiButton',
})

export default UiTypes
