import fileDownload from 'js-file-download'
import { parse } from 'content-disposition-header'

export default class FileManager {
  static iconSrcs = {}

  static async preloadFileTypeIcons() {
    const fileTypes = [
      'icon_pdf',
      'icon_xls',
      'icon_txt',
      'icon_doc',
      'icon_docx',
      'icon_ppt',
      'icon_png',
      'icon_zip',
      'icon_img',
      'icon_folder',
      'unknown',
    ]
    for (const type of fileTypes) {
      try {
        const module = await import(`@/Assets/images/icons/${type}.svg`)
        FileManager.iconSrcs[type] = module.default
      } catch (e) {
        console.error(`Error loading icon for file type ${type}:`, e)
      }
    }
  }

  static downloadFile(response) {
    const parsedDisposition = parse(response.headers['content-disposition'])
    fileDownload(response.data, parsedDisposition.parameters.filename)
  }

  static getFileTypeIcon(name) {
    const iconKey = this.getIconByFileType(name)
    if (FileManager.iconSrcs[iconKey]) {
      return FileManager.iconSrcs[iconKey]
    }

    try {
      FileManager.iconSrcs[iconKey] = module.default
      return module.default
    } catch (e) {
      console.error(`Error loading icon for file type ${iconKey}:`, e)
      return FileManager.iconSrcs['unknown']
    }
  }

  static getFolderTypeIcon() {
    return FileManager.getFileTypeIcon('folder')
  }

  static getIconByFileType(name) {
    if (!_.isString(name)) {
      return 'unknown'
    }

    const filetype = _.last(name.split('.'))

    switch (filetype) {
      case 'pdf':
        return 'icon_pdf'
      case 'csv':
      case 'xls':
      case 'xlsx':
        return 'icon_xls'
      case 'txt':
        return 'icon_txt'
      case 'doc':
        return 'icon_doc'
      case 'docx':
        return 'icon_docx'
      case 'ppt':
      case 'pptx':
        return 'icon_ppt'
      case 'png':
        return 'icon_png'
      case 'zip':
        return 'icon_zip'
      case 'jpg':
      case 'jpeg':
        return 'icon_img'
      case 'folder':
        return 'icon_folder'
      default:
        return 'unknown'
    }
  }

  /**
   *
   * @param {string} name
   * @param {number?} length
   * @param {number?} slice
   * @returns {string|any}
   */
  static getTruncatedFileName(name, length = 25, slice = 7) {
    return name
    if (_.isString(name)) {
      const omission = '…' + name.slice(slice * -1)
      return _.truncate(name, { length, omission })
    }
    return name
  }
}
