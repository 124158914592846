<template>
  <div
    v-if="visible"
    :class="{ 'form-group': true, [customClass]: !!customClass }"
  >
    <div
      class="custom-control custom-checkbox"
      :class="{ 'custom-control-inline': inline }"
    >
      <input
        :id="id || dotKey"
        v-validate="validation"
        :value="booleanValue"
        :data-vv-name="dotKey"
        type="checkbox"
        class="custom-control-input"
        :name="name"
        :checked="checked || booleanValue"
        :disabled="disabled"
        @input="emitChange"
      />
      <label
        v-b-popover.hover.html.top="
          traceMode ? `<div style='width: 500px'>${traceValue}</div>` : false
        "
        class="custom-control-label"
        :for="id || dotKey"
      >
        <slot name="label">
          {{ label }}
          <RequiredNotation :required="required"></RequiredNotation>
        </slot>
      </label>
      <small v-if="description" class="form-text text-muted">
        {{ description }}
      </small>
    </div>

    <ErrorLabel :show-error="showError" :error="error" />

    <small v-if="traceMode" class="trace-dot-key">
      {{ id || traceLabel || dotKey }}
    </small>

    <UiRulesError
      v-if="uiRulesError && traceMode"
      :id="id || dotKey"
      :message="uiRulesError.message"
      :rule="uiRulesError.rule"
    />
  </div>
</template>

<script>
import InputComponent from '@/General/Form/Mixins/InputComponent.js'
import RequiredNotation from '@/General/RequiredNotation.vue'
const TRUE_VALUES = [true, 'true', '1', 1, 'yes']

export default {
  name: 'Checkbox',

  components: { RequiredNotation },

  mixins: [InputComponent],

  props: {
    inline: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    traceValue() {
      return this.prettyPrintJson.toHtml(this.getTrace(this.node), {
        quoteKeys: true,
      })
    },
    booleanValue() {
      return TRUE_VALUES.includes(this.model)
    },
  },

  methods: {
    emitChange(event) {
      this.$emit('input', event.target.checked)
      this.$emit('onChange', event.target.checked)
    },
  },
}
</script>
